import React, { Fragment } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Hero, Loading, SectionFull } from '@website2018/da-dobsonville';
import { Redirect } from 'react-router-dom';

import Actions from '../../components/Actions/Actions';
import Asides from '../../components/Asides/Asides';
import Campaigns from '../../components/Campaigns/Campaigns';
import Champ from '../../components/Champ/Champ';
import MetaHeader from '../../components/Meta/MetaHeader';
import Posts from '../../components/Newsroom/Posts';
import Videos from '../../components/Videos/Videos';

class ProvinceContainer extends React.Component {
  state = {
    client: false
  }

  componentDidMount() {
    this.forceUpdate()
  }

  render() {
    const { error, loading, province:p } = this.props;

    if (loading) return <Loading/>;
    if (error || !p) return <Redirect to={'/not-found'}/>;

    return (
      <Fragment>
        <MetaHeader p={p}/>,
        <Hero
          center
          imageUrl={ p.hero.background_image ? p.hero.background_image.url : null}
          small={false}
          title={ p.hero.headline || p.title.rendered}
          mb={"none"}>
          <Actions type={p.hero.cta_type} actions={p.hero.cta_group} headline={p.hero.group_headline}/>
        </Hero>

        <Champ title={"Subscribe to our newsletter"} formId={"newsletter"}/>

        <SectionFull>
          <div className="col-md-7">
            <Campaigns
              campaigns={p.campaigns}
              title={"Latest Campaigns"}
              view={true}
            />
          </div>
          <div className="col-lg-4 ml-auto" pl="none">
            <Asides asides={p.asides} />
          </div>
        </SectionFull>

        <Videos videos={p.videos} title={`${p.title.rendered} Videos`} />

        <Posts posts={p.posts} title={`${p.title.rendered} Newsroom`} />

      </Fragment>
    );
  }
}


const PROVINCE_QUERY = gql`
  query GetProvince($slug: String!) {
    province(slug:$slug) {
      id
      title {
        rendered
      }
      featured_media {
        id
        source_url
  			title {
  			  rendered
  			}
      }
      asides {
        id
        title {
          rendered
        }
        content{
          rendered
        }
        aside_options
      }
      hero{
        headline
        cta_type
        group_headline
        cta_group{
          title
          link_label
          link_url
        }
        background_image{
          url
        }
      },
      campaigns {
        id
        slug
        title {
          rendered
        }
        excerpt {
          rendered
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
        province {
          id
          name
        }
        external {
          status
          link
        }
      },
      videos {
        id
        videoId
        title {
          rendered
        }
        excerpt {
          rendered
        }
        category {
          id
          name
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
      },
      posts {
        id
        date
        slug
        formattedDate
        title {
          rendered
        }
        category {
          id
          name
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
      }
    },
  }
`;

const withProvince = graphql(PROVINCE_QUERY, {
    options: (props) => {
        return ({
        variables: {
            slug: props.match.params.slug
        }
        })
    },
    props: ({ data }) => ({ ...data }),
});


export default withProvince(ProvinceContainer);
